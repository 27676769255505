(function($) {

  // Creare's 'Implied Consent' EU Cookie Law Banner v:2.4
  // Conceived by Robert Kent, James Bavington & Tom Foyster
  // Modified by Simon Freytag for syntax, namespace, jQuery and Bootstrap
  
  C = {
      // Number of days before the cookie expires, and the banner reappears
      cookieDuration : 60,
  
      // Name of our cookie
      cookieName: 'complianceCookie',
  
      // Value of cookie
      cookieValue: 'on',

      // Message banner message
      bannerMessage: "We use cookies to ensure you get the best experience on our website.",
  
      // Message banner dismiss button
      bannerButton: "OK, I understand",

      // Text alignment
      alertAlign: "center",
      
      // Link text
      buttonClass: "btn-success btn-xs",    
  
      createDiv: function () {
          var banner = $(
              '<div class="alert alert-inverted alert-sm alert-dismissible text-'+
               this.alertAlign +' fade in" ' +
              'role="alert" style="position: fixed; bottom: 0; z-index: 2000; width: 100%; ' +
              'margin-bottom: 0"><div class="container"> ' +
              this.bannerMessage + '<button type="button" class="btn ' +
               this.buttonClass + '" onclick="C.createCookie(C.cookieName, C.cookieValue' +
              ', C.cookieDuration)" style="vertical-align:baseline; margin-left: 1em;" data-dismiss="alert" aria-label="Close">' +
              this.bannerButton + '</button></div></div>'
          );
          $("body").append(banner);
      },
  
      createCookie: function(name, value, days) {
          var expires = "";
          if (days) {
              var date = new Date();
              date.setTime(date.getTime() + (days*24*60*60*1000));
              expires = "; expires=" + date.toGMTString();
          }
          document.cookie = name + "=" + value + expires + "; path=/";
      },
  
      checkCookie: function(name) {
          var nameEQ = name + "=";
          var ca = document.cookie.split(';');
          for(var i = 0; i < ca.length; i++) {
              var c = ca[i];
              while (c.charAt(0)==' ')
                  c = c.substring(1, c.length);
              if (c.indexOf(nameEQ) === 0) 
                  return c.substring(nameEQ.length, c.length);
          }
          return null;
      },
  
      init: function() {
          if (this.checkCookie(this.cookieName) != this.cookieValue)
              this.createDiv();
      }
  };

  $(document).ready(function() {
    C.init();
  });
  
})(jQuery);
