/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        $('.toggle-more').click(function(event) {
          event.preventDefault();
          $('.header-bar').toggleClass('show-all');
        });
        
        $('.menu-item-has-children > a').click(function(event) {
          $(event.currentTarget).focus();
          event.preventDefault();
        });

        $(window).scroll(function() {
          if($(this).scrollTop() > 10) {
            $('body').addClass('is-scrolled');
          }
          else {
            $('body').removeClass('is-scrolled');
          }
        });
        
        $(window).resize(function() {
          $(window).trigger('scroll');
        });
        $(window).trigger('scroll');
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        
        $('a[href^="#"]').click(function(event) {
          var stickyNavigationHeight = $('.section-navigation.sticky').css('position') === 'fixed' ? $('.section-navigation.sticky').height() : 0;
          stickyNavigationHeight += $('.header-bar').height();
          event.preventDefault();
          $('.is-target').removeClass('is-target');
          var targetSelector = $(this).attr('href');
          if($(targetSelector).length > 0) {
            $(targetSelector).addClass('is-target');
            $('body').addClass('has-target');
            $(window).scrollTop($(targetSelector).offset().top - stickyNavigationHeight);
          }
        });
        
        
        $(document).on('click', '[data-toggle="lightbox"]', function(event) {
            event.preventDefault();
            $(this).ekkoLightbox({
              alwaysShowClose: true,
              showArrows: true,
              no_related: true
            });
        });
        
        $(document).on('click', '.product-page-menu-toggle', function(event) {
           event.preventDefault();
            $('.current-menu-parent > a').focus();
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
