(function($) {
  
  var $navigation = $('.section-navigation');

  if(!$navigation.length) {
    return;
  }
  var stickyBreakpoint = $navigation.offset().top - $navigation.height();
  
  var sectionOffsets = [];
  $navigation.find('li a').each(function(i, element) {
    var href = $(element).attr('href');
    var offset = Math.round($(href).offset().top);
    sectionOffsets[i] = offset;
  });
  
  if($navigation.length) {
    $(window).scroll(function() {
      var scrollValue = $(window).scrollTop();
      
      if(scrollValue > stickyBreakpoint) {
        $navigation.addClass('sticky');
        if($navigation.css('position') === 'fixed') {
          $navigation.next().css('margin-top', $navigation.height());
        }
      }
      else {
        $navigation.removeClass('sticky');
        $navigation.next().css('margin-top', '');
      }
      
      $navigation.find('.active').removeClass('active');
      $navigation.find('li a').blur();
      for(var i = 0; i < sectionOffsets.length; i++) {
        if(scrollValue > sectionOffsets[i] - 200) {
          if(i === sectionOffsets.length - 1) {
            $navigation.find('li a').eq(i).addClass('active');
            break;
          }
          continue;
        }
        $navigation.find('li a').eq(i - 1).addClass('active');
        break;
      }
    });
  }
  
})(jQuery);
